/* eslint-disable no-alert */
import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { FiCheck, FiCheckCircle, FiDollarSign } from 'react-icons/fi'
import simulationSelectPercentIcon from '../../assets/simulation-select-percent-icon.svg'
import simulationSelectTimeIcon from '../../assets/simulation-select-time-icon.svg'
import simulationPercentageInfo from '../../assets/simulation-percentage-info.svg'
import simulationFixedValue from '../../assets/simulation-select-fixed-value.svg'
import simulationYearsInfo from '../../assets/simulation-years-info.svg'
import simulationSelectWithdrawIcon from '../../assets/withdraw.png'
import simulationWithdrawInfo from '../../assets/welcome-wallet.svg'

import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'
import { ValorMascarar } from '../../utils/masks'

import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'
import {
  Container,
  Content,
  BoxButtons,
  ColoredBoxInfo,
  ReceiveBox,
  ButtonSelectBox,
  ButtonSimulationCalc,
  BtnVoltar,
  ButtonSimulationValue,
} from './styles'
import getValidationErrors from '../../utils/getValidationErrors'
import InputHidden from '../../components/InputHidden'

const Benefit: React.FC = () => {
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const [displayPercent, setDisplayPercent] = useState(false)
  const [displayTime, setDisplayTime] = useState(false)
  const [displayValorFixo, setDisplayValorFixo] = useState(false)
  const [valorFixoMonth, setValorFixoMonth] = useState(0)
  const [ValorFixoResidual, setValorFixoResidual] = useState(0)
  const [selectedReceive, setSelectedReceive] = usePersistedState<
    'PD' | 'PS' | 'VF' | ''
  >('receiveTypeSelected', '')
  const [totalBalance] = usePersistedState('totalBalance', 0)
  const [balanceWithoutSaque, setBalanceWithoutSaque] = useState(0)
  const [timeValueYears, setTimeValueYears] = usePersistedState<number>(
    'TimeValueYears',
    5,
  )
  const [percentualValuePercent, setPercentualValuePercent] = usePersistedState<
    number
  >('PercentualValuePercent', 1)
  const [percentualValueSaque, setPercentualValueSaque] = usePersistedState<
    number
  >('PercentualValueSaque', 1)
  const [rendaFixa, setRendaFixa] = usePersistedState('RendaFixaValue', 0)
  const saqueValue = useMemo(
    () => formatValue(totalBalance * (percentualValueSaque / 100)),
    [percentualValueSaque, totalBalance],
  )
  const percentualValue = useMemo(
    () => formatValue(balanceWithoutSaque * (percentualValuePercent / 100)),
    [balanceWithoutSaque, percentualValuePercent],
  )
  const timeValue = useMemo(
    () => formatValue(balanceWithoutSaque / (timeValueYears * 12)),
    [balanceWithoutSaque, timeValueYears],
  )

  useEffect(() => {
    percentualValueSaque === 0
      ? setBalanceWithoutSaque(totalBalance)
      : setBalanceWithoutSaque(
          totalBalance - totalBalance * (percentualValueSaque / 100),
        )
  }, [percentualValueSaque, totalBalance])

  const valorUP = 703.31
  const [rendaFixaTempo, setRendaFixaTempo] = useState(0)

  const toggleSelectedReceive = useCallback(
    (selectedReceiveNow: 'PD' | 'PS' | 'VF' | '') => {
      setSelectedReceive(selectedReceiveNow)
      if (selectedReceiveNow === 'PD') {
        setDisplayTime(true)
        setDisplayPercent(false)
        setDisplayValorFixo(false)
      } else if (selectedReceiveNow === 'PS') {
        setDisplayTime(false)
        setDisplayPercent(true)
        setDisplayValorFixo(false)
      } else if (selectedReceiveNow === 'VF') {
        setDisplayTime(false)
        setDisplayPercent(false)
        setDisplayValorFixo(true)
      } else {
        setDisplayPercent(false)
        setDisplayTime(false)
        setDisplayValorFixo(false)
      }
    },
    [setSelectedReceive],
  )

  /* *** SAQUE À VISTA *** */
  const adicPerctSaque = useCallback(() => {
    if (percentualValueSaque >= 25) {
      setPercentualValueSaque(25)
    } else {
      const value = (percentualValueSaque < 0 ? 0 : percentualValueSaque) + 1
      setPercentualValueSaque(value)
    }
  }, [percentualValueSaque, setPercentualValueSaque])

  const tiraPerctSaque = useCallback(() => {
    if (percentualValueSaque <= 0) {
      setPercentualValueSaque(0)
    } else {
      const value = percentualValueSaque - 1
      setPercentualValueSaque(value)
    }
  }, [percentualValueSaque, setPercentualValueSaque])

  /* *** PERCENTUAL SOBRE SALDO *** */
  const adicPerct = useCallback(() => {
    if (percentualValuePercent >= 1.5) {
      setPercentualValuePercent(1.5)
    } else {
      const value = (
        (percentualValuePercent < 0.3 ? 0.3 : percentualValuePercent) + 0.1
      ).toFixed(1)
      setPercentualValuePercent(parseFloat(value))
    }
  }, [percentualValuePercent, setPercentualValuePercent])

  const tiraPerct = useCallback(() => {
    if (percentualValuePercent <= 0.3) {
      setPercentualValuePercent(0.3)
    } else {
      const value = (percentualValuePercent - 0.1).toFixed(1)
      setPercentualValuePercent(parseFloat(value))
    }
  }, [percentualValuePercent, setPercentualValuePercent])

  /* *** TEMPO DETERMINADO *** */
  const adicAno = useCallback(() => {
    if (timeValueYears >= 25) {
      setTimeValueYears(25)
    } else {
      setTimeValueYears(timeValueYears + 1)
    }
  }, [setTimeValueYears, timeValueYears])

  const tiraAno = useCallback(() => {
    if (timeValueYears <= 5) {
      setTimeValueYears(5)
    } else {
      setTimeValueYears(timeValueYears - 1)
    }
  }, [setTimeValueYears, timeValueYears])

  /* *** VALOR FIXO *** */
  const mudarRendaFixa = useCallback(
    valor => {
      const v = valor.replace(',', '').replaceAll('.', '')
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(v) || v === '' || v === undefined) {
        setRendaFixa(rendaFixa)
      } else {
        const m = Math.floor(v.length - 2)
        const a = `${v.substr(0, m)}.${v.substr(m)}`
        const f = parseFloat(a)
        setRendaFixa(f)
      }
    },
    [rendaFixa, setRendaFixa],
  )

  useEffect(() => {
    // eslint-disable-next-line no-bitwise
    const anosRendaFixa = ~~(balanceWithoutSaque / rendaFixa)
    const residualRendaFixa = balanceWithoutSaque % rendaFixa

    setRendaFixaTempo(balanceWithoutSaque / 60)
    setValorFixoMonth(anosRendaFixa)
    setValorFixoResidual(residualRendaFixa)
  }, [balanceWithoutSaque, rendaFixa, totalBalance])

  useEffect(() => {
    toggleSelectedReceive(selectedReceive)
  }, [selectedReceive, toggleSelectedReceive])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          rendaFixa: Yup.string().when('tipo', {
            is: 'VF',
            then: Yup.string()
              .required('Campo obrigatório')
              .test(
                '',
                `Escolha um valor entre ${formatValue(
                  valorUP * 2.5,
                )} e ${formatValue(rendaFixaTempo)}`,
                () =>
                  (valorUP * 2.5 <
                    parseFloat(
                      data.rendaFixa.replace('.', '').replaceAll(',', '.'),
                    ) &&
                    parseFloat(
                      data.rendaFixa.replace('.', '').replaceAll(',', '.'),
                    ) < rendaFixaTempo) ||
                  data.rendaFixa === undefined,
              ),
          }),
        })

        await schema.validate(data, { abortEarly: false })

        history.push('/register')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [history, rendaFixaTempo],
  )

  const handleConfirmValues = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            rendaFixa: ValorMascarar(rendaFixa.toFixed(2).toString()),
          }}
        >
          <Content>
            <strong>Simulação de benefício:</strong>
            <div className="balance">
              <span>Seu saldo projetado</span>
              <h3>{formatValue(totalBalance)}</h3>
              <small>(Seu investimento + rentabilidade)</small>
            </div>

            <ReceiveBox>
              <ButtonSelectBox type="button" selected>
                <img src={simulationSelectWithdrawIcon} alt="vista" />
                <span>Saque à vista</span>
              </ButtonSelectBox>
              <BoxButtons displayed>
                <ButtonSimulationCalc
                  type="button"
                  onClick={() => tiraPerctSaque()}
                >
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationValue type="button">
                  <span>{percentualValueSaque}%</span>
                </ButtonSimulationValue>
                <ButtonSimulationCalc
                  type="button"
                  onClick={() => adicPerctSaque()}
                >
                  +
                </ButtonSimulationCalc>
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="white"
                gradientDirection="right"
                displayed={percentualValueSaque > 0}
              >
                <div>
                  <h3>{saqueValue}*</h3>
                  <small>Você receberá à vista</small>
                  <p>
                    No momento do recebimento do benefício, você poderá sacar
                    até 25% do seu Saldo Projetado.
                    <br />
                    *Valor simulado referente a {percentualValueSaque}% do seu
                    saldo projetado, para saque à vista ou parcelado no momento
                    da aposentadoria.
                  </p>
                </div>
                <img src={simulationWithdrawInfo} alt="Vista" />
              </ColoredBoxInfo>
            </ReceiveBox>
          </Content>

          <Content>
            <h3>
              Escolha aqui a forma de recebimento mensal do seu benefício:
            </h3>

            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('PS')}
                selected={selectedReceive === 'PS'}
              >
                <img src={simulationSelectPercentIcon} alt="Percentual" />
                <span>Percentual sobre o saldo</span>
                <FiCheckCircle />
              </ButtonSelectBox>
              <BoxButtons displayed={displayPercent}>
                <ButtonSimulationCalc type="button" onClick={() => tiraPerct()}>
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationValue type="button">
                  <span>{parseFloat(percentualValuePercent.toFixed(1))}%</span>
                </ButtonSimulationValue>
                <ButtonSimulationCalc type="button" onClick={() => adicPerct()}>
                  +
                </ButtonSimulationCalc>
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="purple"
                gradientDirection="left"
                displayed={displayPercent}
              >
                <div>
                  <h3>{percentualValue}*</h3>
                  <small>Você receberá por mês</small>
                  <p>
                    *Valor simulado referente a {percentualValuePercent}% do seu
                    saldo projetado, com pagamentos mensais efetuados com base
                    no percentual que escolher no momento da aposentadoria, até
                    a extinção do saldo.
                  </p>
                </div>
                <img src={simulationPercentageInfo} alt="Percentual" />
              </ColoredBoxInfo>
            </ReceiveBox>

            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('PD')}
                selected={selectedReceive === 'PD'}
              >
                <img src={simulationSelectTimeIcon} alt="Tempo" />
                <span>Prazo determinado</span>
                <FiCheckCircle />
              </ButtonSelectBox>
              <BoxButtons displayed={displayTime}>
                <ButtonSimulationCalc type="button" onClick={() => tiraAno()}>
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationValue type="button">
                  <span>{timeValueYears}</span>
                </ButtonSimulationValue>
                <ButtonSimulationCalc type="button" onClick={() => adicAno()}>
                  +
                </ButtonSimulationCalc>
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="green"
                gradientDirection="left"
                displayed={displayTime}
              >
                <div>
                  <h3>{timeValue}*</h3>
                  <small>Você receberá por mês</small>
                  <p>
                    *Valor simulado referente a modalidade de prazo determinado
                    com duração de{' '}
                    <b>
                      {timeValueYears} {timeValueYears === 1 ? 'ano' : 'anos'}
                    </b>{' '}
                    calculado com base no seu saldo projetado.
                  </p>
                </div>
                <img src={simulationYearsInfo} alt="Tempo" />
              </ColoredBoxInfo>
            </ReceiveBox>

            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('VF')}
                selected={selectedReceive === 'VF'}
              >
                <img src={simulationFixedValue} alt="Renda Fixa" />
                <span>Valor Fixo</span>
                <FiCheckCircle />
              </ButtonSelectBox>
              <BoxButtons displayed={displayValorFixo}>
                <Input
                  icon={FiDollarSign}
                  name="rendaFixa"
                  mask="currency"
                  type="text"
                  placeholder="Quero receber por mês:"
                  onChange={e => mudarRendaFixa(e.target.value)}
                />
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="orange"
                gradientDirection="right"
                displayed={displayValorFixo}
              >
                <div>
                  <h3>{formatValue(rendaFixa)}*</h3>
                  <small>Você receberá por mês</small>
                  <p>
                    *Renda mensal em valor fixo, expresso em moeda corrente
                    nacional, não podendo seu valor ser inferior a 2.5 UP ou 5
                    anos. <br /> (Unidade de Previdência: {formatValue(valorUP)}
                    ).
                  </p>
                  {/* {ValorFixoResidual !== 0 ? (
                    <p>
                      Saldo residual (pago no ultimo mês):{' '}
                      {formatValue(parseFloat(ValorFixoResidual.toFixed(2)))}
                    </p>
                  ) : (
                    <></>
                  )} */}
                </div>
                <img src={simulationYearsInfo} alt="Tempo" />
              </ColoredBoxInfo>
            </ReceiveBox>
          </Content>
          <InputHidden name="tipo" type="hidden" value={selectedReceive} />
        </Form>

        <Button
          type="button"
          fontSize="normal"
          color="green"
          width="large"
          onClick={handleConfirmValues}
          disabled={selectedReceive === ''}
        >
          <FiCheck size={40} />
          Faça aqui sua adesão!
        </Button>

        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Benefit
