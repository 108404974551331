import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FiArrowRightCircle } from 'react-icons/fi'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Container, Content } from './styles'

const BemVindo: React.FC = () => {
  const history = useHistory()

  const handleStart = useCallback(async () => {
    history.push('/contribution')
  }, [history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Olá, você é empregado da Rumos?</strong>
          <span>
            Então comece agora mesmo a planejar o seu futuro fazendo sua adesão
            ao Plano Duprev CD.
          </span>
          <Button color="green" onClick={handleStart} width="large">
            Simule aqui sua adesão!
            <FiArrowRightCircle size={45} />
          </Button>
          <small>
            O Plano Duprev CD é um plano de previdência oferecido aos empregados
            que não possuem vinculo a outro plano de benefícios administrado
            pela Rumos ou a outro plano de benefícios patrocinado pelas
            patrocinadoras.
          </small>
          <small>
            Como participante do Plano de Benefícios, você contribuirá com uma
            parte e a empresa com o mesmo valor, limitado as regras
            regulamentares. O objetivo principal é formar uma reserva de
            poupança e garantir maior segurança financeira para o seu futuro.
          </small>
        </Content>
      </Container>
    </>
  )
}

export default BemVindo
