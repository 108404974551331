/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */

import React, { useRef, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Switch from 'react-switch'
import moment from 'moment'
import * as Yup from 'yup'

import { FiClock } from 'react-icons/fi'
import { BiCake } from 'react-icons/bi'

import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import calculaIdade from '../../utils/calculaIdade'

import Input from '../../components/Input'
import Button from '../../components/Button'
import Header from '../../components/Header'

import { UserData, UserDetails } from '../../utils/interfaces'
import { Container, Content, Line, BtnVoltar } from './styles'
import InputSelect from '../../components/InputSelect'

const Contribution: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )

  const [idadeApos, setIdadeApos] = useState(userDetails.age)
  const [patrocinadora, setPatrocinadora] = useState({
    label: userData.dcrPatrocinadora,
    value: userData.patrocinadora,
  })

  const [acceptTerms, setAcceptTerms] = useState(false)
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleChangePatrocinadora = useCallback(e => {
    const t = e
    setPatrocinadora(t)
  }, [])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleSubmit = useCallback(
    async data => {
      try {
        const now = new Date()
        const bday = data.birthdate.split('/').reverse().join('-')
        const ano = bday.split('-')[0]
        const idadeAtual = calculaIdade(bday)
        const anosContrib2 = idadeApos - (now.getFullYear() - parseInt(ano, 10))

        const idadeMinima = anosContrib2 < 5 ? idadeAtual + 5 : 50

        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          birthdate: Yup.string()
            .required('Campo obrigatório')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.birthdate.split('/').reverse().join('-')) ||
                data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  data.birthdate.split('/').reverse().join('-'),
                ).isValid() || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                calculaIdade(data.birthdate.split('/').reverse().join('-')) <=
                  115 || data.birthdate === '',
            ),
          age: Yup.number()
            .typeError('Campo obrigatório')
            .required(
              'Digite a idade desejada para a aposentadoria para realizar a simulação',
            )
            .min(
              idadeMinima < 55 ? 55 : idadeMinima,
              `Idade inválida. Por favor, escolha uma idade igual ou superior a ${
                idadeMinima < 55 ? 55 : idadeMinima
              } anos`,
            )
            .max(115, 'Idade inválida.'),
          patrocinadora: Yup.string().required('Patrocinadora é obrigatória'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserDetails({
          ...userDetails,
          years: anosContrib2,
          age: idadeApos,
        })

        await setUserData({
          ...userData,
          birthdate: bday,
          patrocinadora: patrocinadora.value,
          dcrPatrocinadora: patrocinadora.label,
        })

        history.push('/contribution-values')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      history,
      idadeApos,
      patrocinadora,
      setUserData,
      setUserDetails,
      userData,
      userDetails,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            birthdate:
              userData.birthdate === undefined
                ? ''
                : userData.birthdate.split('-').reverse().join('/'),
            age: userDetails.age,
            patrocinadora: userData.patrocinadora,
          }}
        >
          <Content>
            <strong>Contribuição básica</strong>
            <p>
              Aqui você irá definir sua contribuição para o seu Plano de
              Previdência. Os percentuais definidos serão descontados
              mensalmente do seu salário. A patrocinadora também contribuirá
              mensalmente para a formação do seu benefício futuro, no mesmo
              valor da sua contribuição básica (veja sua simulação na
              sequência).
            </p>
            <Input
              icon={BiCake}
              name="birthdate"
              mask="date"
              placeholder="Data de nascimento"
            />
            <Input
              icon={FiClock}
              type="number"
              name="age"
              value={idadeApos}
              sizeBox="large"
              min={50}
              sufix="anos"
              placeholder="Qual a idade desejada para a aposentadoria?"
              onChange={e => setIdadeApos(parseInt(e.target.value, 10))}
            />
            <InputSelect
              name="patrocinadora"
              value={patrocinadora}
              options={[
                { label: 'Corteva', value: 101 },
                { label: 'Corteva Sementes', value: 102 },
                { label: 'Danisco', value: 109 },
                { label: 'Dow Agrosciences', value: 128 },
                { label: 'Dupont', value: 119 },
                { label: 'N&B Ingredients', value: 132 },
                { label: 'Performance Eletronics', value: 131 },
                { label: 'Performance Specilty', value: 112 },
                { label: 'Rumos', value: 105 },
                { label: 'Solae', value: 110 },
              ]}
              onChange={e => handleChangePatrocinadora(e)}
              placeholder="Patrocinadora"
            />

            <Line />

            <div className="terms">
              <small>
                Ao continuar navegando no site, você concorda com as condições
                de{' '}
                <a
                  href="https://rumosprevidencia.com.br/governanca/codigos-e-politicas/"
                  target="_blank"
                >
                  Privacidade
                </a>{' '}
                e{' '}
                <a
                  href="https://rumosprevidencia.com.br/governanca/codigos-e-politicas/"
                  target="_blank"
                >
                  Termos de uso
                </a>{' '}
                do site Rumos.
              </small>
              <Switch
                onChange={toggleAcceptTerms}
                checked={acceptTerms}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
                offColor="#DEE3E1"
                offHandleColor="#fff"
                onHandleColor="#fff"
                onColor="#31D19E"
              />
            </div>
          </Content>
        </Form>

        <Button
          type="submit"
          color="green"
          onClick={handleClick}
          disabled={!acceptTerms}
        >
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/')}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Contribution
