/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { FiDollarSign, FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import Collapse from '@material-ui/core/Collapse'

import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  BtnVoltar,
  ButtonController,
  URContent,
  URBox,
  InfoContent,
  Line,
} from './styles'
import { UserDetails } from '../../utils/interfaces'
import Input from '../../components/Input'
import getValidationErrors from '../../utils/getValidationErrors'

const ContributionValues: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [vlrCtbBasica, setVlrCtbBasica] = useState(
    userDetails.contribuicaoBasica > 0 ? userDetails.contribuicaoBasica : 0,
  )
  const [vlrCtbPatrocinadora, setVlrCtbPatrocinadora] = useState(
    userDetails.contribuicaoPatrocinadora > 0
      ? userDetails.contribuicaoPatrocinadora
      : 0,
  )
  const [vlrCtbVarPatrocinadora, setVlrCtbVarPatrocinadora] = useState(
    userDetails.contribuicaoSuplementarPatrocinadora > 0
      ? userDetails.contribuicaoSuplementarPatrocinadora
      : 0,
  )
  const [vlrCtbTotal, setVlrCtbTotal] = useState(
    userDetails.contribuicaoBasica * 2 > 0
      ? userDetails.contribuicaoBasica * 2
      : 0,
  )
  const [pctCtbBas, setPctCtbBas] = useState(
    userDetails.pctContribuicaoBasica > 0
      ? userDetails.pctContribuicaoBasica
      : 6,
  )
  const [pctCtbPPR, setPctCtbPPR] = useState(
    userDetails.pctContribuicaoPPR > 0 ? userDetails.pctContribuicaoPPR : 0,
  )
  const [pctCtbDecTerc, setPctCtbDecTerc] = useState(
    userDetails.pctContribuicaoDecTerceiro > 0
      ? userDetails.pctContribuicaoDecTerceiro
      : 0,
  )

  const [ur] = useState(813)
  const [salarioValue, setSalarioValue] = useState(
    userDetails.salario > 0 ? userDetails.salario : 0,
  )

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  /* CALCULO DE CONTRIBUIÇÃO NORMAL */
  useEffect(() => {
    const vlrContribPartic = salarioValue * (pctCtbBas / 100)
    let vlrContribPatroc = 0
    let vlrContribPatrocVar = vlrContribPartic * 0.5

    if (pctCtbBas > 6) {
      vlrContribPatroc = salarioValue * 0.06
    } else {
      vlrContribPatroc = vlrContribPartic * 1
    }

    if (vlrContribPatrocVar > salarioValue * 0.03) {
      vlrContribPatrocVar = salarioValue * 0.03
    }

    setVlrCtbBasica(parseFloat(vlrContribPartic.toFixed(2)))
    setVlrCtbPatrocinadora(parseFloat(vlrContribPatroc.toFixed(2)))
    setVlrCtbVarPatrocinadora(parseFloat(vlrContribPatrocVar.toFixed(2)))
    setVlrCtbTotal(vlrCtbBasica + vlrCtbPatrocinadora + vlrCtbVarPatrocinadora)
  }, [
    salarioValue,
    pctCtbBas,
    ur,
    vlrCtbBasica,
    vlrCtbPatrocinadora,
    vlrCtbVarPatrocinadora,
  ])

  const minusContrib = useCallback(() => {
    if (pctCtbBas <= 1) {
      setPctCtbBas(1)
    } else {
      setPctCtbBas(pctCtbBas - 1)
    }
  }, [pctCtbBas])

  const plusContrib = useCallback(() => {
    if (pctCtbBas > 12) {
      setPctCtbBas(12)
    } else {
      setPctCtbBas(pctCtbBas + 1)
    }
  }, [pctCtbBas])

  const minusContribPPR = useCallback(() => {
    if (pctCtbPPR <= 0) {
      setPctCtbPPR(0)
    } else {
      setPctCtbPPR(pctCtbPPR - 1)
    }
  }, [pctCtbPPR])

  const plusContribPPR = useCallback(() => {
    if (pctCtbPPR > 12) {
      setPctCtbPPR(12)
    } else {
      setPctCtbPPR(pctCtbPPR + 1)
    }
  }, [pctCtbPPR])

  const minusContribDecTerc = useCallback(() => {
    if (pctCtbDecTerc <= 0) {
      setPctCtbDecTerc(0)
    } else {
      setPctCtbDecTerc(pctCtbDecTerc - 1)
    }
  }, [pctCtbDecTerc])

  const plusContribDecTerc = useCallback(() => {
    if (pctCtbDecTerc > 12) {
      setPctCtbDecTerc(12)
    } else {
      setPctCtbDecTerc(pctCtbDecTerc + 1)
    }
  }, [pctCtbDecTerc])

  const mudarSalario = useCallback(valor => {
    const v = valor.replace(',', '').replaceAll('.', '')
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(v) || v === '' || v === undefined) {
      setSalarioValue(0)
    } else {
      const m = Math.floor(v.length - 2)
      const a = `${v.substr(0, m)}.${v.substr(m)}`
      const f = parseFloat(a)
      setSalarioValue(f)
    }
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          salario: Yup.string()
            .test('', 'Salário inválido.', () => salarioValue <= 100000)
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserDetails({
          ...userDetails,
          salario: salarioValue,
          contribuicaoBasica: vlrCtbBasica,
          contribuicaoPatrocinadora: vlrCtbPatrocinadora,
          contribuicaoSuplementarPatrocinadora: vlrCtbVarPatrocinadora,
          pctContribuicaoBasica: pctCtbBas,
          pctContribuicaoPPR: pctCtbPPR,
          pctContribuicaoDecTerceiro: pctCtbDecTerc,
        })

        history.push('/simulation')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      history,
      pctCtbBas,
      pctCtbDecTerc,
      pctCtbPPR,
      salarioValue,
      setUserDetails,
      userDetails,
      vlrCtbBasica,
      vlrCtbPatrocinadora,
      vlrCtbVarPatrocinadora,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={userDetails}>
          <Content>
            <strong>Contribuição básica</strong>
            <p>
              Aqui você irá definir sua contribuição para o seu Plano de
              Previdência. Os percentuais definidos serão descontados
              mensalmente do seu salário. A patrocinadora também contribuirá
              mensalmente para a formação do seu benefício futuro, no mesmo
              valor da sua contribuição básica.
            </p>
            <Input
              icon={FiDollarSign}
              name="salario"
              mask="currency"
              type="text"
              placeholder="Qual o seu salário base R$?"
              onChange={e => mudarSalario(e.target.value)}
            />

            <Collapse in={salarioValue !== 0} className="collapse">
              <URContent>
                <URBox>
                  <div className="UR-info">
                    <p>Contribuição mensal</p>
                  </div>
                  <div>
                    <ButtonController
                      type="button"
                      disabled={pctCtbBas === 1}
                      isSelected={pctCtbBas > 1}
                      onClick={() => minusContrib()}
                    >
                      <FiMinusCircle />
                    </ButtonController>
                    <strong>{pctCtbBas}%</strong>
                    <ButtonController
                      type="button"
                      disabled={pctCtbBas === 12}
                      isSelected={pctCtbBas < 12}
                      onClick={() => plusContrib()}
                    >
                      <FiPlusCircle />
                    </ButtonController>
                  </div>
                  <strong>{formatValue(vlrCtbBasica)}</strong>
                </URBox>
                <URBox>
                  <div className="UR-info">
                    <p>Contribuição esporádica sobre o PPR</p>
                  </div>
                  <div>
                    <ButtonController
                      type="button"
                      disabled={pctCtbPPR === 0}
                      isSelected={pctCtbPPR > 0}
                      onClick={() => minusContribPPR()}
                    >
                      <FiMinusCircle />
                    </ButtonController>
                    <strong>{pctCtbPPR}%</strong>
                    <ButtonController
                      type="button"
                      disabled={pctCtbPPR === 12}
                      isSelected={pctCtbPPR < 12}
                      onClick={() => plusContribPPR()}
                    >
                      <FiPlusCircle />
                    </ButtonController>
                  </div>
                  <strong>-</strong>
                </URBox>
                <URBox>
                  <div className="UR-info">
                    <p>Contribuição esporádica sobre o 13º</p>
                  </div>
                  <div>
                    <ButtonController
                      type="button"
                      disabled={pctCtbDecTerc === 0}
                      isSelected={pctCtbDecTerc > 0}
                      onClick={() => minusContribDecTerc()}
                    >
                      <FiMinusCircle />
                    </ButtonController>
                    <strong>{pctCtbDecTerc}%</strong>
                    <ButtonController
                      type="button"
                      disabled={pctCtbDecTerc === 12}
                      isSelected={pctCtbDecTerc < 12}
                      onClick={() => plusContribDecTerc()}
                    >
                      <FiPlusCircle />
                    </ButtonController>
                  </div>
                  <strong>-</strong>
                </URBox>
              </URContent>
            </Collapse>

            <Line />

            <InfoContent>
              <div>
                <p>Valor da contribuição básica do Participante: </p>
                <strong>{formatValue(vlrCtbBasica)}</strong>
              </div>
              <div>
                <p>Valor da contribuição complementar da Patrocinadora: </p>
                <strong>{formatValue(vlrCtbPatrocinadora)}</strong>
              </div>
              <div>
                <p>Valor da contribuição variável da Patrocinadora: </p>
                <strong>{formatValue(vlrCtbVarPatrocinadora)}</strong>
              </div>
              <div>
                <div>
                  <p>Valor total de contribuição básica ao Plano: </p>
                  <small>(participante + patrocinadora)</small>
                </div>
                <strong>{formatValue(vlrCtbTotal)}</strong>
              </div>
              <span>
                Obs.: A contribuição da patrocinadora é suspensa para
                participantes com 65 anos ou mais.
              </span>
            </InfoContent>
          </Content>
        </Form>

        <Button
          type="submit"
          color="green"
          onClick={handleClick}
          disabled={salarioValue < 1}
        >
          Próximo
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default ContributionValues
