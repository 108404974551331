import React, { useEffect } from 'react'

import endImage from '../../assets/end-image.svg'

import Header from '../../components/Header'
import { Container, Content } from './styles'

const End: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem('@Rumos:erroProps')
    localStorage.removeItem('@Rumos:participantsGroup')
    localStorage.removeItem('@Rumos:PercentualValuePercent')
    localStorage.removeItem('@Rumos:PercentualValueSaque')
    localStorage.removeItem('@Rumos:receiveTypeSelected')
    localStorage.removeItem('@Rumos:RendaFixaValue')
    localStorage.removeItem('@Rumos:TimeValueYears')
    localStorage.removeItem('@Rumos:totalBalance')
    localStorage.removeItem('@Rumos:userData')
    localStorage.removeItem('@Rumos:userDetails')
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Pronto! Seu pedido de aquisição do Plano Duprev CD da Rumos foi
            salvo com sucesso!
          </strong>

          <img src={endImage} alt="endImage" />

          <p>
            O próximo passo é entrar no e-mail e acessar o link do
            autoatendimento para completar seu cadastro.
          </p>

          <div>
            <strong>ACESSE O NOSSO SITE:</strong>
            <a href="https://rumosprevidencia.com.br/">
              www.rumosprevidencia.com.br
            </a>
          </div>
        </Content>
      </Container>
    </>
  )
}

export default End
