/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { FiCheck } from 'react-icons/fi'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { addYears, differenceInMonths, parseISO } from 'date-fns'

import { formatValue, formatValueReduced } from '../../utils/formatValues'
import usePersistedState from '../../hooks/usePersistedState'
import calculaIdade from '../../utils/calculaIdade'
import { Valor } from '../../utils/masks'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  GraphWrapper,
  BtnVoltar,
  InfoValuesBox,
  TextContainer,
} from './styles'
import { UserDetails, UserData } from '../../utils/interfaces'

interface CustomTooltipInterface {
  active: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
  label: string
}

interface Values {
  year: number
  invested: number
  finalBalance: number
  rentability: number
}

const Simulation: React.FC = () => {
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const history = useHistory()
  const [values, setValues] = useState<Values[]>([])
  const [totalInvested, setTotalInvested] = useState(0)
  const [totalBalance, setTotalBalance] = usePersistedState('totalBalance', 0)
  const [launched, setLaunched] = useState(false)

  const [totalInvestedPartic, setTotalInvestedPartic] = useState(0)
  const [totalInvestedPatroc, setTotalInvestedPatroc] = useState(0)

  const idadeAtual = calculaIdade(userData.birthdate)

  const createValues = useCallback(() => {
    const valuesCalculated: Values[] = []
    let totalInvestedTemp = totalInvested
    let temporaryFinalBalance = 0
    let tempMonthInvestedPartic = 0
    let tempTotalInvestedPartic = 0
    let tempMonthInvestedPatroc = 0
    let tempTotalInvestedPatroc = 0

    let contBasicaPartic = userDetails.contribuicaoBasica
    let contPatroc =
      userDetails.contribuicaoPatrocinadora +
      userDetails.contribuicaoSuplementarPatrocinadora

    const dataAposentadoria = addYears(
      parseISO(userData.birthdate),
      userDetails.age,
    )

    const contribDifference = differenceInMonths(dataAposentadoria, new Date()) // 242 meses
    const rentability = 0.407412378
    let finalBalance = 0
    let counter = 1

    for (counter; counter <= contribDifference + 2; counter += 1) {
      const monthTotalInvested = contBasicaPartic + contPatroc
      totalInvestedTemp += monthTotalInvested

      finalBalance =
        temporaryFinalBalance * (rentability / 100) +
        (monthTotalInvested + temporaryFinalBalance)

      temporaryFinalBalance = finalBalance

      /* Investimento do Participante */
      tempMonthInvestedPartic = contBasicaPartic
      tempTotalInvestedPartic += tempMonthInvestedPartic

      /* Investimento da Patrocinadora */
      tempMonthInvestedPatroc = idadeAtual >= 65 ? 0 : contPatroc
      tempTotalInvestedPatroc += tempMonthInvestedPatroc

      /* EVOLUÇÃO SALARIAL */
      if (counter % 12 === 0) {
        contBasicaPartic += contBasicaPartic * 0.02
        contPatroc += contPatroc * 0.02
      }

      const val: Values = {
        // eslint-disable-next-line no-bitwise
        year: new Date().getFullYear() + ~~(counter / 12),
        invested: totalInvestedTemp,
        rentability,
        finalBalance,
      }
      valuesCalculated.push(val)
    }

    setTotalInvested(totalInvestedTemp)
    setTotalBalance(temporaryFinalBalance)
    setTotalInvestedPartic(tempTotalInvestedPartic)
    setTotalInvestedPatroc(tempTotalInvestedPatroc)
    setValues(valuesCalculated)
    setLaunched(true)
  }, [
    idadeAtual,
    setTotalBalance,
    totalInvested,
    userData.birthdate,
    userDetails.age,
    userDetails.contribuicaoBasica,
    userDetails.contribuicaoPatrocinadora,
    userDetails.contribuicaoSuplementarPatrocinadora,
  ])

  useEffect(() => {
    if (!launched) createValues()
  }, [createValues, launched])

  const graphValues = values.map(value => ({
    year: value.year,
    value: value.finalBalance,
    formattedValue: Valor(value.finalBalance.toFixed(2)),
  }))

  function CustomTooltip({ active, payload, label }: CustomTooltipInterface) {
    if (active) {
      return (
        <div className="tooltip">
          <h4>Ano: {label}</h4>
          <p>
            Saldo: <span>{Valor(payload[0].value.toFixed(2))}</span>
          </p>
        </div>
      )
    }
    return null
  }

  const handleChangeValues = useCallback(() => {
    history.push('/contribution-values')
  }, [history])

  const handleConfirmValues = useCallback(() => {
    history.push('/benefit')
  }, [history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="values-box">
            <InfoValuesBox color="orange" gradientDirection="right">
              <span>Valor Total Investido</span>
              <h3>{formatValue(totalInvested)}</h3>
              <small>
                Total Participante: {formatValue(totalInvestedPartic)}
              </small>
              <small>
                Total Patrocinadora: {formatValue(totalInvestedPatroc)}
              </small>
            </InfoValuesBox>

            <InfoValuesBox color="green" gradientDirection="right">
              <span>Seu saldo projetado</span>
              <h3>{formatValue(totalBalance)}</h3>
              <small>Seu investimento + rentabilidade</small>
            </InfoValuesBox>
          </div>
          <article>
            <p>
              Surpreso com o resultado? <br />
              Navegue no gráfico e acompanhe a evolução do seu patrimônio no
              período desejado.
            </p>
          </article>

          <GraphWrapper>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={graphValues}>
                <defs>
                  <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#0072BB44" stopOpacity={0.8} />
                    <stop
                      offset="95%"
                      stopColor="#0072BB88"
                      stopOpacity={0.05}
                    />
                  </linearGradient>
                </defs>

                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#0072BB"
                  fillOpacity={1}
                  fill="url(#color)"
                />

                <Tooltip
                  content={
                    <CustomTooltip active={false} payload={2} label="" />
                  }
                />
                <XAxis name="Ano" dataKey="year" />
                <YAxis
                  name="Saldo"
                  dataKey="value"
                  width={75}
                  axisLine={false}
                  tickLine={false}
                  tickCount={5}
                  tickFormatter={number =>
                    `${formatValueReduced(
                      parseInt(number.toString(), 10),
                      3,
                      'R$',
                    )}`}
                />
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  opacity={0.5}
                />
              </AreaChart>
            </ResponsiveContainer>
          </GraphWrapper>

          <TextContainer>
            <h3>Transparência</h3>
            <p>
              1. Os valores apresentados são apenas estimativas e não garantem
              nenhum direito ao participante. O direito efetivo dependerá dos
              dados reais na data da efetiva concessão do benefício de
              aposentadoria ou resgate.
            </p>
            <p>
              2. O valor de seu Saldo Projetado foi calculado com base no saldo
              atual acrescido das contribuições e rentabilidade anual taxa de
              juros de 5% até a data da aposentadoria na idade estipulada na
              simulação.
            </p>
            <p>
              3. O valor da Renda Projetada foi calculada com base em uma
              rentabilidade anual de 5% com tempo de recebimento da simulação e
              não considerou eventuais impostos e taxas administrativas.
            </p>
            <p>
              Consulte o Regulamento do seu Plano de Benefícios para maiores
              informações.
            </p>
          </TextContainer>
          <small className="comment">
            Esta simulação não representa garantia de valor de benefício futuro.
            Informamos que o valor da aposentadoria será apurado no momento da
            concessão do benefício, o que pode resultar em um valor diferente
            daquele apresentado no simulador.
          </small>
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="green"
          width="large"
          onClick={handleConfirmValues}
        >
          <FiCheck size={40} />
          Simular o valor do benefício
        </Button>

        <Button type="button" fontSize="small" onClick={handleChangeValues}>
          Ops! Quero alterar os valores e simular novamente
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Simulation
