import { transparentize } from 'polished';
import styled from 'styled-components';

interface BtnContatoProps {
  isActive: boolean;
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  strong {
    text-align: center;
    font-size: 18px;
    color: ${props => props.theme.colors.text};

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  > button {
    margin: 0 auto;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;

    > small {
      width: 90%;
    }
  }
`

export const RadioButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position:relative;

  width: 100%;
  padding: 10px;
  margin: 12px 0;

  background: initial;
  border-radius: 4px;
  border: 1px solid ${transparentize(0.4, '#AEAEAE')};
  color: ${props => props.theme.colors.placeholder};
  transition: .2s;

  > label {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.1em;
  }

  > div {
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &::placeholder {
      color: ${ props => props.theme.colors.placeholder};
    }
  }
`

export const BtnContato = styled.button<BtnContatoProps>`
  width: 120px;
  height: 45px;

  border: none;
  border-radius: 4px;
  font-size: 12px;
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  background: ${props => props.isActive? 'linear-gradient(15deg, #4D92C0, #A0C1DE)' : '#F0F2F5'};

  &:focus {
    text-decoration: underline;
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`
