export default {
  title: 'dark',
  colors: {
    primary: '#4B6F81',
    secondary: '#2F4550',
    tertiary: '#1E2C33',

    background: '#182328',
    backgroundSecondary: '#121B1E',
    backgroundTertiary: '#0C1214',

    placeholder: '#F4F4F9',
    text: '#fff',
    textSecondary: '#E4E4F1',

    golden: '#F29602',

    mainColor: '#BACB60',
    secondaryColor: '#C7FFC4',
    blue: '#A0C1DE',
    yellow: '#E8C11F',


    error: '#C53030',
    errorText: '#fddede',

    success: '#28A745',
    sucessText: '#CAFFD5',
  },
}
