import styled from 'styled-components'

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.main`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px;

  strong {
    display: flex;
    text-align: center;
    align-self: center;
    justify-content: center;
    color: ${props => props.theme.colors.text};
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin-top: 3px;
    margin-bottom: 8px;

    strong {
      font-size: 16px;
      color: ${props => props.theme.colors.mainColor};
      font-weight: bolder;
    }

    small {
      font-size: 12px;
      display: flex;
      font-weight: bold;
    }
  }

  span {
    font-size: 11px;
    color: ${props => props.theme.colors.placeholder};
    font-style: italic;
    margin-top: 15px;
  }

  @media screen and (max-width: 600px) {
    p {
      text-align: left;
    }

    > div {
      margin-top: 8px;

      small {
        text-align: left;
      }
    }
  }
`

export const Participants = styled.div`
  border-bottom: 1px solid #EEE;

  h3 {
    font-size: 18px;
    color: ${props => props.theme.colors.text}
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: ${props => props.theme.colors.text};
  }

  small {
    font-size: 12px;
    color: #666360;
  }

  .titular,
  .dependents {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 6px 5px 10px;
    margin-bottom: 10px;
  }

  .tributation {
    margin-bottom: 15px;
  }

  .planos {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
`

export const Confirmation = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 20px;

  strong {
    text-align: center;
    align-self: center;
    color: ${props => props.theme.colors.mainColor};
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 15px;
  }

  p {
    font-size: 14px;
  }

  > div {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 25px;
    text-align: right;

    small {
      margin-right: 12px;
      font-weight: bold;
    }

    a {
      text-decoration: none;
    }
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const Line = styled.div`
  width: 80%;
  height: 1px;
  margin: 15px auto 20px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

